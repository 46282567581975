import { BillStatuses } from "@legacy/core/utils/enums"

import TableViewTab from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab"
import TableViewTabs from "@organisms/Table/TableViewControls/TableViewTabs/TableViewTabs"

import { BILLS_ROUTES } from "@routes/bills"

export const BillTableViewTabs = () => (
    <TableViewTabs>
        <TableViewTab
            id="all_open"
            phase="open"
            title="All Open"
            isDefaultActiveTabForPhase={true}
            endpointKwargs={[
                ["status", BillStatuses.pending],
                ["status", BillStatuses.unpaid],
                ["ordering", "date_due"],
            ]}
            listEndpoint={BILLS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={BILLS_ROUTES.COUNT}
        />
        <TableViewTab
            id="pending"
            phase="open"
            title="Pending"
            endpointKwargs={[
                ["status", BillStatuses.pending],
                ["ordering", "date_due"],
            ]}
            listEndpoint={BILLS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={BILLS_ROUTES.COUNT}
        />
        <TableViewTab
            id="outstanding"
            phase="open"
            title="Outstanding"
            endpointKwargs={[
                ["status", BillStatuses.unpaid],
                ["is_overdue", "false"],
                ["ordering", "date_due"],
            ]}
            listEndpoint={BILLS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={BILLS_ROUTES.COUNT}
        />
        <TableViewTab
            id="overdue"
            phase="open"
            title="Overdue"
            endpointKwargs={[
                ["status", BillStatuses.unpaid],
                ["is_overdue", "true"],
                ["ordering", "date_due"],
            ]}
            listEndpoint={BILLS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={BILLS_ROUTES.COUNT}
        />
        <TableViewTab
            id="all_closed"
            phase="closed"
            title="All Closed"
            isDefaultActiveTabForPhase={true}
            endpointKwargs={[
                ["status", BillStatuses.paid],
                ["status", BillStatuses.voided],
                ["ordering", "-date_due"],
            ]}
            listEndpoint={BILLS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={BILLS_ROUTES.COUNT}
        />
        <TableViewTab
            id="paid"
            phase="closed"
            title="Paid"
            endpointKwargs={[
                ["status", BillStatuses.paid],
                ["ordering", "-date_due"],
            ]}
            listEndpoint={BILLS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={BILLS_ROUTES.COUNT}
        />
        <TableViewTab
            id="voided"
            phase="closed"
            title="Voided"
            endpointKwargs={[
                ["status", BillStatuses.voided],
                ["ordering", "-date_due"],
            ]}
            listEndpoint={BILLS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={BILLS_ROUTES.COUNT}
        />
    </TableViewTabs>
)
